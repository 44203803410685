import { PencilIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';

import business_img1 from '../../../../assets/images/samples/business_img1.png';
import trash_can_icon from '../../../../assets/images/icons/trash_can.svg';
import featured_img_sample from '../../../../assets/images/samples/16_9_ratio.jpeg';
import Button from "../../../../components/Button";
import Select, { OptionProps } from "../../../../components/Select";
import Toggle from "../../../../components/Switch";
import TextInput from "../../../../components/TextInput";
import { useBusinessQuery, useBusinessUpdateMutation, useUploadBusinessPreviewMutation, util as BusinessApiUtil, useBusinessDeleteMutation, useUploadBusinessLogoMutation } from "../../../../services/business";
import { useOptionsQuery } from "../../../../services/categories";
import { useForm } from "react-hook-form";
import { BusinessUpdateFields } from "../../../../store/business/types";
import Box from "../../../../components/Skeleton/Box";
import Circle from "../../../../components/Skeleton/Circle";
import { isValidImageAspectRatio } from "../../../../helpers/image";
import { useDeleteFileMutation } from "../../../../services/file";
import { Preview } from "../../../../models/Business";
import { useAppDispatch } from "../../../../store/hooks";
import { BUSINESS_TAG_TYPES } from "../../../../services/business/types";
import DeleteModal from "../../../AdminDashboard/Modals/DeleteModal";

export default () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const hiddenFileInput = useRef<any>(undefined);
  const [image_file, setImage] = useState<any>({ file: '', url: '' });
  const [delete_modal, setDeleteModal] = useState(false);
  const [show_address, setShowAddress] = useState(false);
  const [hide_phone_no, setHidePhoneNo] = useState(false);
  const featured_img_ref = useRef<HTMLInputElement | null>(null);
  const [is_saving, setIsSaving] = useState(false);
  const params = useParams();
  const id = +(params.business_id || 0);
  const { data, isLoading } = useBusinessQuery(Number(id));
  const { data: categories_data } = useOptionsQuery();
  const [uploadBusinessLogo] = useUploadBusinessLogoMutation();
  const [businessUpdate, response] = useBusinessUpdateMutation();
  const [businessDelete, delete_response] = useBusinessDeleteMutation();
  const [uploadBusinessImagePreview] = useUploadBusinessPreviewMutation();
  const [deletePreviewFile, { isLoading: deletePreviewFileLoading }] = useDeleteFileMutation();
  const business_data = data?.data;
  const category_options = (categories_data?.data.categories || []).map(cd => ({ label: cd.name, value: cd.id }));
  const [selected_category, setSelectedCategory] = useState<OptionProps<number>>(category_options[0]);
  const [form_data, setFormData] = useState<any>({
    logo: '',
    business_name: '',
    address_line_1: '',
    state: '',
    city: '',
    zip: '',
    phone_number: '',
    is_address_hidden: false,
    is_phone_number_hidden: false,
  });
  const [previews, setPreviews] = useState<Array<Preview>>([]);

  useEffect(() => {
    if (!!categories_data && !!category_options.length) {
      const find_category = category_options.filter((co) => co.value === business_data?.category_id)[0];
      setSelectedCategory(find_category);
    }

    if (!!business_data) {
      setFormData({
        logo: business_data?.logo,
        business_name: business_data?.name,
        address_line_1: business_data?.address_line_1,
        state: business_data?.state,
        city: business_data?.city,
        zip: business_data?.zip,
        phone_number: business_data?.phone_number,
        is_address_hidden: business_data?.is_address_hidden || false,
        is_phone_number_hidden: business_data?.is_phone_number_hidden || false,
      });
      setPreviews(business_data?.previews || []);
    }
  }, [data]);

  const handleUploadClick = () => {
    featured_img_ref.current?.click();
  }

  const handleDeletePreview = async (file_id: number) => {
    try {
      await deletePreviewFile(file_id);
      dispatch(BusinessApiUtil.invalidateTags([BUSINESS_TAG_TYPES.BUSINESS]));
    } catch (e) {
      console.log('handleDeletePreview ERROR', e);
    } finally {

    }
  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    // file change handler
    // validate ratio here
    const img_files = e.target.files || [];
    const new_previews = [...previews];
    const for_upload: Array<File> = [];
    let has_invalid = false;

    for (let i = 0, l = img_files.length; i < l && !has_invalid; i++) {

      const is_valid = await isValidImageAspectRatio(URL.createObjectURL(img_files[i]), { min_height: 450, min_width: 800, ratio: 16 / 9 });

      if (!is_valid) {
        has_invalid = true;
      }

      // new_previews.push(URL.createObjectURL(img_files[i]));
      for_upload.push(img_files[i]);
    }

    if (has_invalid) {
      Swal.fire({
        title: 'Error Uploading Image!',
        text: 'Please make sure to upload image with 16:9 aspect ratio and try again.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });

      return;
    }

    if (for_upload.length > 0) {
      const form_data = new FormData();
      form_data.append("image", for_upload[0]);
      const image_upload_reponse = await uploadBusinessImagePreview({ id, image: form_data });
    }

    setPreviews(new_previews);
  }

  const handleSubmit = async () => {
    const update_params: BusinessUpdateFields = {
      id: Number(id),
      category_id: 1,
      ...form_data,
      name: form_data.business_name,
    };
    setIsSaving(true);
    
    if (!!image_file.url && !!image_file.file) { // upload business logo
      const image = new FormData();
      image.append('image', image_file.file || '');
      const businessLogoParams = {
          id: Number(id),
          image,
      };
      await uploadBusinessLogo(businessLogoParams);
    }

    businessUpdate(update_params)
      .unwrap()
      .then(res => {
        Swal.fire({
          title: 'Success!',
          text: 'Updated Successfully!',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      })
      .then(err => console.log('error ', err))
      .finally(() => setIsSaving(false));
  }

  const handleRemove = async (id: number) => {
    try {
      const xhr = await businessDelete(id);

      if (!xhr) {
        throw xhr;
      }

      Swal.fire({
        title: 'Success!',
        text: 'Deleted Successfully!',
        icon: 'success',
        confirmButtonText: 'Ok',
        didClose() {
          navigate('/app/profile/business');
        },
      })

    } catch (e) {
      // catch error
    }
  }

  const handleOnUploadClick = () => {
    hiddenFileInput.current?.click();
  }

  const handleOnImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
      if (!!e.target.files && !!e.target.files.length) {
          const img_file = e.target.files[0];
          const img_url = URL.createObjectURL(img_file);
          const is_valid = await isValidImageAspectRatio(img_url, { min_height: 512, min_width: 512, ratio: 1 / 1 });

          if (!is_valid) {
              return;
          }

          setFormData({ ...form_data, logo: img_url })
          setImage({
              file: img_file,
              url: img_url,
          });
      }
  }

  if (!!isLoading) {
    return (
      <div className="container m-auto mt-10 rounded-sm border border-grey3 bg-white py-10 px-11">
        <Box className="rounded-md h-8 w-64" />
        <div className="flex flex-row pt-11">
          <Box className="rounded-md  w-28 h-28" />
          <div className="flex flex-col items-start px-7">
            <Box className="rounded-md h-8 w-64" />
            <Box className="rounded-md h-8 w-64 mt-5" />
          </div>
        </div>
        <div className="w-full md:w-2/3 pt-6">

          <Box className="rounded-md mb-2 h-10" />
          <Box className="rounded-md mb-2 h-10" />
          <Box className="rounded-md mb-2 h-10" />
          <div className="grid grid-flow-row grid-cols-3 gap-3">
            <Box className="rounded-md mb-2 h-10" />
            <Box className="rounded-md mb-2 h-10" />
            <Box className="rounded-md mb-2 h-10" />
          </div>
          <Box className="rounded-md mb-2 h-10" />
          <Box className="rounded-md mb-2 h-10" />
        </div>
      </div>
    );
  }

  return (
    <div className="container m-auto mt-10 rounded-sm border border-grey3 bg-white py-10 px-11">
      <h4>Business Details</h4>
      <div className="flex flex-row pt-11">
        <div className='border border-grey rounded-md w-28 h-28 overflow-hidden p-2'>
          <img src={form_data?.logo || business_img1} className="w-24 h-24 object-contain" />
        </div>
        <div className="flex flex-col items-start px-7">
          <input type='file' accept="image/png, image/svg, image/jpeg" ref={hiddenFileInput} style={{ visibility: 'hidden' }} onChange={handleOnImageChange} />
          <Button outlined label="UPDATE" onClick={handleOnUploadClick} />
          <button className="mt-5 text-sm text-red" onClick={() => setFormData({ ...form_data, logo: business_data?.logo || '' })}>
            Remove
          </button>
        </div>
      </div>
      <div className="w-full md:w-2/3 pt-6">
        <TextInput
          width="w-full"
          placeholder="Business Name"
          value={form_data.business_name}
          onChange={e => setFormData({ ...form_data, business_name: e.target.value })}
        />
        <TextInput
          width="w-full"
          placeholder="Address"
          value={form_data.address_line_1}
          onChange={e => setFormData({ ...form_data, address_line_1: e.target.value })}
        />
        <div className="grid grid-flow-row grid-cols-3 gap-3">
          <TextInput
            width="w-full"
            placeholder="State"
            value={form_data.state}
            onChange={e => setFormData({ ...form_data, state: e.target.value })}
          />
          <TextInput
            width="w-full"
            placeholder="City"
            value={form_data.city}
            onChange={e => setFormData({ ...form_data, city: e.target.value })}
          />
          <TextInput
            width="w-full"
            placeholder="Zip Code"
            value={form_data.zip}
            onChange={e => setFormData({ ...form_data, zip: e.target.value })}
          />
        </div>
        <Select
          placeholder='Category'
          onChange={setSelectedCategory}
          options={category_options || []}
          value={selected_category}
          width="w-full"
        />
        <TextInput
          width="w-full"
          placeholder="Contact Number"
          value={form_data.phone_number}
          onChange={e => setFormData({ ...form_data, phone_number: e.target.value })}
        />
      </div>
      <div className="pt-12">
        <h4>Profile Details</h4>
        <div className="pt-5">
          <div className="flex flex-row pt-5 items-center">
            <Toggle
              onChange={value => setFormData({ ...form_data, is_address_hidden: !value })}
              checked={!(form_data.is_address_hidden)}
            />
            <span className="pl-10 text-sm">Show Address</span>
          </div>
          <div className="flex flex-row pt-5 items-center">
            <Toggle
              onChange={value => setFormData({ ...form_data, is_phone_number_hidden: value })}
              checked={!!(form_data.is_phone_number_hidden)}
            />
            <span className="pl-10 text-sm">Hide Phone Number</span>
          </div>
        </div>
      </div>
      <div className="pt-12">
        <h4>Featured Images</h4>
        <div className="pt-5 flex flex-row flex-wrap gap-1">
          {
            previews.map((p, i) => {
              return (
                <div className="w-40 h-24 relative gap-1 mr-2" key={i}>
                  <img src={p.url} className="h-full w-full object-cover" />
                  <button className="absolute top-1 right-2" onClick={() => handleDeletePreview(p.id)} disabled={deletePreviewFileLoading}>
                    <div className="h-7 w-7 rounded-full bg-black flex justify-center items-center opacity-60 hover:opacity-75">
                      <img src={trash_can_icon} className="h-3 w-3 object-contain" />
                    </div>
                  </button>
                </div>
              );
            })
          }
          <div className="w-40 h-24 border border-dashed border-grey2 bg-grey1 relative flex flex-col justify-center items-center px-5 hover:cursor-pointer" onClick={handleUploadClick}>
            <input type='file' accept="image/png, image/svg, image/jpeg" ref={featured_img_ref} className="hidden" onChange={handleFileChange} />
            <div className="text-xl">+</div>
            <div className="text-center text-xs text-grey2">
              Must be 800wx450h or 16:9 in ratio
            </div>
          </div>
        </div>
      </div>
      <div className="pt-12">
        <h4>Business</h4>
        <div className="pt-5 flex flex-row items-center">
          <Button outlined outline_color="red" label="DELETE BUSINESS" onClick={() => setDeleteModal(true)}/>
          <div className="text-sm italic pl-4">*this can’t be undone</div>
        </div>
      </div>
      <div className="pt-12">
        <div className="pt-7">
          <Button label="SAVE CHANGES" onClick={handleSubmit} disabled={is_saving} />
        </div>
      </div>
      <DeleteModal
        data={{}}
        is_open={delete_modal}
        title="Are you sure you want to delete this business?"
        image={business_data?.logo}
        name={business_data?.name || ''}
        desc={business_data?.address_line_1}
        onRequestClose={() => {
          setDeleteModal(false);
        }}
        round_img={false}
        handleSubmit={() => handleRemove(business_data?.id || 0)}
      />
    </div>
  );
}

type Inputs = {
  business_name: string
  address_line_1: string
  state: string
  city: string
  zip: string
  phone_number: string
};
import { StarIcon } from "@heroicons/react/24/outline";

const Rating = (props: { rate: number }) => {
  return (
    <div className='flex justify-start items-center'>
      {
        [1, 2, 3, 4, 5].map(i => <StarIcon width={14} height={14} color="#FFCA2C" fill={(props.rate || 0) >= i ? "#FFCA2C" : "#e5e7eb"} />)
      }
    </div>
  );
}

export default Rating;
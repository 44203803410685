import { StarIcon } from '@heroicons/react/24/outline';
import coffee_place from '../../assets/images/samples/coffee_place.png';
import bread from '../../assets/images/samples/bread.jpeg';
import pin from '../../assets/images/icons/pin.svg';
import map_nav from '../../assets/images/icons/map_nav.svg';
import phone from '../../assets/images/icons/phone.svg';
import add_circle_dashed from '../../assets/images/icons/add_circle_dashed.svg';
import heart_gray from '../../assets/images/icons/heart_gray.svg';

import Button from '../../components/Button';
import Tabs from './components/Tabs';
import ImageSlider from './components/ImageSlider';
import BreadCrumb from '../../components/BreadCrumb';
import { useParams } from 'react-router-dom';
import { useBusinessQuery, useBusinessReviewsQuery } from '../../services/business';
import { Link } from 'react-router-dom';
import { formatNameForImg } from '../../helpers/utils';

const Desktop = () => {
  const params = useParams();
  const id = params.business_id;
  const { data, isLoading, isError } = useBusinessQuery(Number(id));
  const { data: reviews, isLoading: reviews_loading, isError: reviews_error } = useBusinessReviewsQuery(Number(id));
  const business_data = data?.data;
  const reviews_data = reviews?.data || [];
  const total_reviews = reviews?.meta?.total || 0;
  const default_img = `https://getstream.io/random_svg/?name=${formatNameForImg(business_data?.name || '')}`;
  const previews = !!business_data?.previews.length ? business_data?.previews : [];

  return (
    <div className='w-full'>
      {!!previews.length && <ImageSlider images={previews} />}
      {
        !previews.length && <div className='w-full h-[450px] relative'>
          <div className="slide-item h-full cursor-pointer" onClick={() => {}}>
            <div className='absolute top-4 right-4 hover:cursor-pointer'>
              <img src={heart_gray} className='w-6 h-5 object-center' alt="favorite" />
            </div>
            <div className='w-full h-full flex justify-center items-center'>
              <div className='flex flex-col  justify-center items-center'>
                <img src={add_circle_dashed} alt="Add New" />
                <span className='text-sm pt-2'>Add Featured Image</span>
              </div>
            </div>
          </div>
        </div>
      }
      {/* <BreadCrumb /> */}
      <div className='w-full bg-ghost-white'>
        <div className='container m-auto pt-2 pb-5'>
          <BreadCrumb />
          <div className='grid grid-flow-row grid-cols-5 gap-2 w-full pt-4'>
            <div className='flex col-span-5 md:col-span-3'>
              <div className='rounded-full w-28 h-28 flex justify-center items-center overflow-hidden'>
                <img src={business_data?.logo || default_img} className="w-28 h-28 object-contain" alt={business_data?.name} />
              </div>
              <div className='pl-8 pt-2'>
                <h3>{business_data?.name}</h3>
                <div className='pt-1 flex items-center'>
                  <div className='flex items-center text-sm pr-3'>
                    <img src={pin} className="pr-3" alt="Location" />
                      {business_data?.address_line_1}
                  </div>
                  <div className='flex items-center text-sm pr-3'>
                    <div className='h-1 w-1 bg-grey4 rounded-sm' />
                    <img src={map_nav} className="px-3" alt="Email" />
                    {business_data?.website_url}
                  </div>
                  <div className='flex items-center text-sm pr-3'>
                    <div className='h-1 w-1 bg-grey4 rounded-sm' />
                    <img src={phone} className="px-3" alt="Phone" />
                    {business_data?.phone_number}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-span-5 md:col-span-2 grid grid-flow-row grid-cols-1 gap-2 place-content-start place-items-center md:place-items-end'>
              <div className='flex justify-center items-center'>
                <div className='pr-4 flex items-start md:items-center flex-col md:flex-row'>
                  <div className='flex flex-col justify-center items-center'>
                    {total_reviews ? <span className='text-sm'>{(data?.data.ratings)?.toFixed(2) || 0} of {total_reviews} Reviews</span> : <span className='text-sm'>No reviews yet</span>}
                    <div className='flex justify-start items-center'>
                      {
                        [1,2,3,4,5].map(i => {
                          const rating = (data?.data.ratings || 0);
                          const filled = (data?.data.ratings || 0) >= i;

                          if (((i - rating) < 1 && (i - rating) > 0)) {
                            return <div className='relative w-6 h-6'>
                              <StarIcon width={24} height={24} className="mr-1 star-rating half absolute top-0 left-0 bottom-0 right-0" fill='gold' />
                              <StarIcon width={24} height={24} className="mr-1 star-rating half-2 absolute top-0 left-0 bottom-0 right-0" fill='white' />
                              </div>
                          }

                          return <StarIcon width={24} height={24} className="mr-1 star-rating" fill={filled ? 'gold' : 'white'} />
                        })
                      }
                    </div>
                  </div>
                </div>
                <Button
                  as={Link}
                  label="CONTACT VENDOR"
                  height='h-16'
                  width='mx-2 w-200'
                  bg_color='bg-green'
                  to={`/app/profile/messages?contact_id=${id}&type=business`}
                  className='flex items-center justify-center'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='w-full pt-5'>
        <Tabs />
      </div>
    </div>
  );
}

export default Desktop;
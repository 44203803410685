import Button from "../../../../components/Button";
import Select from "../../../../components/Select";
import { useNavigate, useParams } from "react-router-dom";
import { ORDER_STATUS } from "../../../../constants/purchase_history";
import { OrderStatusChip } from "../../../../components/Chip";
import ReportItem from "../../../../components/ReportItem";
import { useBusinessReviewsQuery, usePostReviewMutation, useProductListQuery } from "../../../../services/business";
import ProductCard from "../../../../components/ProductCard";
import { StarIcon } from "@heroicons/react/20/solid";
import Option from "../../../../models/Options";
import { classNames } from "../../../../helpers/styles";
import { useState } from "react";
import ReviewItem from "../../../BusinessDetails/components/ReviewItem";
import Modal from "../../../../components/Modal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ReviewForm from "../ReviewForm";
import { useAppSelector } from "../../../../store/hooks";

const Reviews = () => {
  const params = useParams();
  const id = params.business_id || 0;
  const user = useAppSelector(store => store.auth.user);

  const [published, setPublished] = useState<number>(1);
  const [review_submitted_modal, setReviewSubmittedModal] = useState<boolean>(false);
  const [write_review_modal, setWriteReviewModal] = useState<boolean>(false);
  const [postReview, {isLoading, isSuccess}] = usePostReviewMutation();
  const { data, isLoading: list_loading } = useBusinessReviewsQuery(+id);
  const list = data?.data || [];
  return (
    <div className="container m-auto rounded-sm py-10 px-11">
      <div className="flex flex-col pt-6">
        <div className="flex justify-between">
          <h4>Rating & Reviews (9)</h4>
          <Button text="WRITE A REVIEW" outlined onClick={() => setWriteReviewModal(true)} />
        </div>
        <div className="flex gap-5 pt-6">
          <div className="flex flex-col pr-10">
            <span className=" text-[45px]">4.5</span>
            <span>of 35 Reviews</span>
            <div className='flex justify-start items-center'>
              <StarIcon width={14} height={14} color="#FFCA2C" />
              <StarIcon width={14} height={14} color="#FFCA2C" />
              <StarIcon width={14} height={14} color="#FFCA2C" />
              <StarIcon width={14} height={14} color="#FFCA2C" />
              <StarIcon width={14} height={14} color="#FFCA2C" />
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-2">
            {
              list.map((d: any) => <ReviewItem
                // replies={[1,2,3]}
                data={d}
                key={d?.id} />)
            }
          </div>
        </div>
      </div>
      <Modal isOpen={review_submitted_modal} contentLabel="Example Modal" className="modal-container">
        <div className='bg-white px-28 py-16 relative'>
          <button className='absolute top-6 right-6 w-6 h-6' onClick={() => setReviewSubmittedModal(false)}>
            <XMarkIcon />
          </button>
            <div className="text-center">
              <h4>Thank you for submitting your review!</h4>
              <div className="text-sm py-5">Lorem Ipsum has been the industry's standard dummy text.</div>
              <Button text="GOT IT!" width="w-150" onClick={() => setReviewSubmittedModal(false)} />
            </div>
        </div>
      </Modal>
      <ReviewForm
        open={write_review_modal}
        setOpen={() => setWriteReviewModal(false)}
        loading={isLoading}
        user={{
          name: user?.name,
          email: user?.email,
        }}
        onSubmit={async (data: any) => {
          const params: any = {
            ...data,
            id
          };
          try {
            const xhr = await postReview(params);
          } catch (e: any) {
            
          } finally {
            setWriteReviewModal(false);
            setReviewSubmittedModal(true);
          }

        }}
      />
    </div>
  );
}

const Radio = ({ options, value, onChange }: { options: Array<Option>, value: any, onChange(value: any): void }) => {
  return (
    <div className="rounded-md border border-border-default overflow-hidden">
      {
        options.map(o => {
          return (
            <button onClick={() => onChange(o.value)} className={classNames("border-l border-border-default h-12 px-10 first-of-type:border-0", o.value != value ? "bg-grey" : "" )}>
              <span className={classNames("text-sm bg-transparent", o.value == value ? "font-bold" : "")}>{o.label}</span>
            </button>
          );
        })
      }
    </div>
  );
}
export default Reviews;
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithCSRF } from '../fetchBaseQuery';
import { store } from '../../store/store';
import { AddProdPromoResponse, Credentials, ImageCredentials, ImageResponse } from './types';
import { saveAddProdPromoResponse, saveImageResponse } from '../../store/add_prod_promo/slice';
import Cookies from 'js-cookie';

export const addProdPromoApi = createApi({
  reducerPath: 'addProdPromoApi',
  baseQuery: baseQueryWithCSRF,
  endpoints: (builder) => ({
    createProdPromo: builder.mutation<AddProdPromoResponse, Credentials>({

      query({ business_id, ...credentials }) {
        // const headers = {'authorization' : `Bearer ${Cookies.get('XSRF-TOKEN')}`}
        return {
          url: `api/int/businesses/${business_id}/items`,
          method: 'POST',
          body: credentials
        }
      },
      transformResponse: (response: AddProdPromoResponse) => {
        store.dispatch(saveAddProdPromoResponse(response));
        return response;
      }
    }),
    uploadImage: builder.mutation<ImageResponse, any>({
      query({ business_id, item_id, image }) {
        return {
          url: `/api/int/businesses/${business_id}/items/${item_id}/image`,
          method: 'POST',
          body: image,
        }
      },
      transformResponse: (response: ImageResponse) => {
        store.dispatch(saveImageResponse(response));
        return response;
      }
    })
  })
})

export const { useCreateProdPromoMutation, useUploadImageMutation } = addProdPromoApi

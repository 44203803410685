import { Business, Product } from "../../store/business/types"

export type BusinessListResponse = {
  data: Array<Business>
}

export type BusinessDetailsResponse = {
  data: Business
}

export type ProductListResponse = {
  data: Array<Product>
}

export const BUSINESS_TAG_TYPES = {
  BUSINESS: 'BUSINESS',
  PRODUCT: 'PRODUCT',
  BUSINESS_REVIEWS: 'BUSINESS_REVIEWS',
  BUSINESS_POST_REVIEW: 'BUSINESS_POST_REVIEW',
}
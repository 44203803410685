import './index.scss';
import frog from '../../../../assets/images/samples/frog.jpeg';
import bread from '../../../../assets/images/samples/bread.jpeg';
// import baking from '../../../../assets/images/samples/baking.jpeg';
import add_circle_dashed from '../../../../assets/images/icons/add_circle_dashed.svg';
import heart_gray from '../../../../assets/images/icons/heart_gray.svg';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../../../helpers/styles';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const items = [
  {
    image: frog,
    name: "frog",
  },
  {
    image: '',
    name: "no image",
  },
  {
    image: bread,
    name: "Bread",
  },
  // {
  //   image: baking,
  //   name: "baking",
  // },
  // {
  //   image: frog,
  //   name: "frog",
  // },
  // {
  //   image: baking,
  //   name: "baking",
  // },
];

const ImageSlider = ({ images, ...props }: { images: Array<{ url: string, id: number }> }) => {
  const upload_img_ref = useRef<HTMLInputElement>(null);
  const slider_ref = useRef<HTMLDivElement>(null);
  const [current_slide, setCurrentSlide] = useState(0);

  // const scrollToSlide = useCallback(() => {
  //   if (!!slider_ref) {
  //     const slide_elem = slider_ref?.current?.children[current_slide];
  //     slide_elem?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
  //   }
  // }, [slider_ref, current_slide]);

  const scrollToSlide = () => {
    const elem = document.getElementById('slide_'+current_slide);
    elem?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
  }

  useEffect(() => {
    // scrollToSlide();
    setCurrentSlide(2);
  }, []);

  useEffect(() => {
    if (!!images && images.length) {
      scrollToSlide();
    }
  }, [current_slide, images]);

  const handleLeftClick = () => {
    if (current_slide === 0) return;
    setCurrentSlide(current_slide - 1);
  }

  const handleRightClick = () => {
    if (current_slide === items.length) return;
    setCurrentSlide(current_slide + 1);
  }

  const handleFileChange = (e: ChangeEvent) => {

  }

  const handleAddImageOnClick = () => {
    upload_img_ref?.current?.click();
  }

  return (
    <div id="ImageSlider" className='w-full h-[450px] relative'>
      <Carousel
        className='h-[450px]'
        dynamicHeight={false}
        centerMode
        centerSlidePercentage={50}
        showThumbs={false}
        renderArrowPrev={(onClick) => {
          return <NavButton className="left" icon={<ChevronLeftIcon className='h-6' />} onClick={onClick}/>;
        }}
        renderArrowNext={(onClick) => {
          return <NavButton className="right" icon={<ChevronRightIcon className='h-6' />} onClick={onClick}/>;
        }}
      >
      {
          images.map((item, i) => {
            if (!item.url) {
              return (
                <div className="slide-item h-full cursor-pointer" key={i} onClick={handleAddImageOnClick}>
                  <div className='absolute top-4 right-4 hover:cursor-pointer'>
                    <img src={heart_gray} className='w-6 h-5 object-center' alt="favorite" />
                  </div>
                  <div className='w-full h-full flex justify-center items-center'>
                    <div className='flex flex-col  justify-center items-center'>
                      <img src={add_circle_dashed} alt="Add New" />
                      <span className='text-sm pt-2'>Add Featured Image</span>
                    </div>
                  </div>
                </div>
              );
            }

            return (
              <div className="slide-item h-[450px]" id={'slide_' + item.id} key={i}>
                <img src={item.url} alt={item.url} className='w-full h-[450px] object-cover' />
              </div>
            );
          })
        }
      </Carousel>
    </div>
  );

  return (
    <div id="ImageSlider" className='w-full relative'>
      <input type='file' accept="image/png, image/svg, image/jpeg" ref={upload_img_ref} className="hidden" onChange={handleFileChange}/>
      <div className="image-slider-container" ref={slider_ref}>
        <div className="slide-item h-full" />
        {
          images.map((item, i) => {
            if (!item.url) {
              return (
                <div className="slide-item h-full cursor-pointer" key={i} onClick={handleAddImageOnClick}>
                  <div className='absolute top-4 right-4 hover:cursor-pointer'>
                    <img src={heart_gray} className='w-6 h-5 object-center' alt="favorite" />
                  </div>
                  <div className='w-full h-full flex justify-center items-center'>
                    <div className='flex flex-col  justify-center items-center'>
                      <img src={add_circle_dashed} alt="Add New" />
                      <span className='text-sm pt-2'>Add Featured Image</span>
                    </div>
                  </div>
                </div>
              );
            }

            return (
              <div className="slide-item h-full" id={'slide_' + item.id} key={i}>
                <img src={item.url} alt={item.url} className='w-full h-full object-cover' />
              </div>
            );
          })
        }
        <div className="slide-item h-full" />
      </div>
      {(current_slide > 0) && <NavButton className="left" icon={<ChevronLeftIcon className='h-6' />} onClick={handleLeftClick}/>}
      {(current_slide < items.length) && <NavButton className="right" icon={<ChevronRightIcon className='h-6 ' />} onClick={handleRightClick}/>}
    </div>
  );
}

const NavButton = ({ className, ...props }: any) => {
  return (
    <button className={classNames('nav-button', className)} {...props}>
      {props.icon}
    </button>
  );
}

export default ImageSlider;
import React, { ChangeEvent, useRef, useState } from 'react';
import Modal from '../../../components/Modal';
import BaseModal from '../../../components/Modal/BaseModal';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';

import AddIcon from '../../../assets/images/add_icon.svg';
import { classNames } from '../../../helpers/styles';
import { isValidImageAspectRatio } from '../../../helpers/image';
import { generateImageByName } from '../../../helpers/utils';

const DeleteModal = ({ round_img = true,  ...props}: any) => {
    const data = props?.data || {};
    const hiddenFileInput = useRef<any>(undefined);
    console.log('data ', data);
    const handleSubmit = () => {
        console.log('submit');
    }
    const handleCancel = () => {
        console.log('submit');
    }
    console.log('props?.is_open ', props?.is_open);
    return (
        <BaseModal
            title={props.title}
            className="modal-container"
            is_open={props?.is_open}
            onRequestClose={props?.onRequestClose}
            header={props.header}
        >
            <div className='flex justify-center'>
                <div className='inline-block'>
                    <div className='border-4 rounded-lg flex flex-row justify-center items-center py-2 mt-5'>
                        <div className='rounded-md w-20 h-20 overflow-hidden p-1'>
                            <img src={props.image} className={`w-full h-full ${!props.round_img ? 'rounded-full' : ''}`} />
                        </div>
                        <div className='pl-4'>
                            <span className='text-h5 font-bold'>{props.name || ''}</span>
                            <p>{props.desc || ''}</p>
                        </div>
                    </div>
                    <div className="mt-8 flex justify-center gap-5">
                        <Button outlined outline_color="red" text="CANCEL" width="w-md" onClick={props.onRequestClose} />
                        <Button bg_color='bg-red/95 hover:bg-red/98 active:bg-red' text="YES" width="w-md" onClick={() => props.handleSubmit(data)} />
                    </div>
                </div>
            </div>
        </BaseModal>
    );
}

export default DeleteModal;
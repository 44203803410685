import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import './index.scss';
import AddIcon from '../../assets/images/add_icon.svg';
import TextInput from '../../components/TextInput';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import { actions } from '../../store/add_prod_promo/slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getImageSize } from 'react-image-size';
import heart_white from '../../assets/images/icons/heart_white.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PRODUCT_SERVICE, PROMO_CODE } from '../../constants/business';
import { useCreateProdPromoMutation, useUploadImageMutation } from '../../services/add_prod_promo';
import { useBusinessQuery } from '../../services/business';
import Swal from 'sweetalert2';
import { isValidImageAspectRatio } from '../../helpers/image';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const appearanceColors = [
  {
    bgColor: '#EC4444',
    borderColor: 'hover:border-red-orange hover:border'
  },
  {
    bgColor: '#DB6B55',
    borderColor: 'hover:border-rusty-orange hover:border'
  },
  {
    bgColor: '#2BA9E0',
    borderColor: 'hover:border-sky-blue hover:border'
  },
  {
    bgColor: '#43B8BA',
    borderColor: 'hover:border-blue-green hover:border'
  },
  {
    bgColor: '#8859B4',
    borderColor: 'hover:border-purple hover:border'
  },
  {
    bgColor: '#85A763',
    borderColor: 'hover:border-green hover:border'
  },
  {
    bgColor: '#122E7F',
    borderColor: 'hover:border-dark-blue hover:border'
  }
]

const AddProdPromo = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const item_type = Number(new URLSearchParams(location.search).get('t') || PRODUCT_SERVICE);
  const [valueChecking, setValueChecking] = useState<number>(item_type);
  const { business_id } = useParams();

  const { data: business_data } = useBusinessQuery(Number(business_id));

  const prod_promo_title = useAppSelector(store => store.addProdPromo.prod_promo_title);
  const add_type = useAppSelector(store => store.addProdPromo.add_type);
  const card_color = useAppSelector(store => store.addProdPromo.card_color);
  const short_desc = useAppSelector(store => store.addProdPromo.short_desc);
  const full_desc = useAppSelector(store => store.addProdPromo.full_desc);
  const price_in_usd = useAppSelector(store => store.addProdPromo.price_in_usd);
  const expiration_days = useAppSelector(store => store.addProdPromo.expiration_days);
  const percentage = useAppSelector(store => store.addProdPromo.percentage);
  const cash_back = useAppSelector(store => store.addProdPromo.cash_back);
  const no_expiration = useAppSelector(store => store.addProdPromo.no_expiration);
  const original_price = useAppSelector(store => store.addProdPromo.original_price);
  const percentage_display = useAppSelector(store => store.addProdPromo.percentage_display);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const img_src = useAppSelector(store => store.addProdPromo.img_src);
  const on_save = useAppSelector(store => store.addProdPromo.on_save);
  const img_error = useAppSelector(store => store.addProdPromo.img_error);
  const sku = useAppSelector(store => store.addProdPromo.sku);
  const image = useAppSelector(store => store.addProdPromo.image);
  const [createProdPromo, { isLoading, isSuccess, isError }] = useCreateProdPromoMutation();
  const [uploadImage] = useUploadImageMutation();
  const navigate = useNavigate();
  const [img_upload, setImgUpload] = useState<any>({
    file: undefined,
    url: undefined,
  });

  useEffect(() => {
    dispatch(actions.reset());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        title: 'Success!',
        text: 'Item Added Successfully!',
        icon: 'success',
        confirmButtonText: 'Ok',
        didClose() {
          navigate(`/app/business/${business_id}`);
        },
      });
    }

    if (isError) {
      Swal.fire({
        title: 'An Error Occurred!',
        text: 'Please try again later.',
        icon: 'error',
        confirmButtonText: 'Ok',
        // didClose() {
        //     navigate(`/app/business/${business_id}`);
        // },
      });
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    setValueChecking(item_type);
  }, [item_type, location]);

  const changeTitle = (value: string) => {
    dispatch(actions.onChangeTitle(value));
  };

  const changeCardColor = (bgColor: string) => {
    dispatch(actions.onChangeCardColor(bgColor));
  }

  const changeShortDesc = (value: string) => {
    dispatch(actions.onChangeShortDesc(value));
  }

  const changeFullDescription = (value: string) => {
    dispatch(actions.onChangeFullDesc(value));
  }

  const changePriceInUSD = (value: string) => {
    computePercentage(parseInt(value), original_price);
    dispatch(actions.onChangePrice(parseInt(value)));
  }

  const changeSKU = (value: string) => {
    dispatch(actions.onChangeSKU(value));
  }

  const onClickCount = (value: string) => {
    dispatch(actions.onChangeExpirationDays(value));
  }

  const onChangeNoExpiration = (value: boolean) => {
    dispatch(actions.onChangeNoExpiration(value));
  }

  const onChangePercentage = (value: string) => {
    dispatch(actions.onChangePercentage(value));
  }

  const onChangeCashBack = (value: string) => {
    dispatch(actions.onChangeCashBack(value));
  }

  const onChangeOriginalPrice = (value: string) => {
    computePercentage(price_in_usd, parseInt(value));
    dispatch(actions.onChangeOriginalPrice(parseInt(value)));
  }

  const computePercentage = (price: number, org_price: number) => {
    if (price !== 0 && org_price !== 0) {
      let per_val = ((1 - (price / org_price)) * 100).toFixed(2);
      dispatch(actions.onChangePercentageDisplay(per_val));
    }
  }

  const handleClick = () => {
    hiddenFileInput.current?.click();
  }

  const validateImageSize = async (image: string) => {
    const { width, height } = await getImageSize(image);

    if (width < 512 && height < 512) {
      dispatch(actions.onUpdateImgError(true));
      return true;
    } else if (width === height) {
      return false;
    }
  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const img_file = e.target.files[0];
      const img_url = URL.createObjectURL(img_file);
      const validate_image = await isValidImageAspectRatio(img_url, { min_height: 512, min_width: 512, ratio: 1 / 1 });

      if (!validate_image) {
        Swal.fire({
          title: 'Invalid Image!',
          text: 'Accepted files jpg, png and must be atleast 512x512 and with 1:1 ratio.',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        return;
      }

      setImgUpload({
        file: img_file,
        url: img_url,
      });
    }
  }

  const createProdPromoDetails = async () => {
    try {
      const value = {
        type: item_type === 1 ? 'Product' : 'PromoCode',
        name: prod_promo_title,
        short_description: short_desc,
        full_description: full_desc,
        price: price_in_usd,
        original_price: original_price,
        sku: sku,
        expiration_days: expiration_days,
        color: card_color,
        discount_percentage: percentage,
        discount_description: cash_back
      }
      const xhr: any = await createProdPromo({ business_id, ...value });
      const item_id = xhr?.data?.data.id;

      if (!!img_upload.url && !!item_id) {
        const img_for_upload = new FormData();
        img_for_upload.append('image', img_upload.file);
        const value = {
          image: img_for_upload
        }
        uploadImage({ business_id, item_id, ...value });
      }
      // navigate(`/app/business/${business_id}`);
      dispatch(actions.onSave(true));
    } catch (e) {
      console.log(e);
    }
  }

  // if (isSuccess) {
  // alert('Item successfully created!');


  // navigate(`/app/business/${business_id}`);
  // }

  const onCancel = () => {
    dispatch(actions.onChangeTitle(''));
    dispatch(actions.onChangeCardColor('#C6C6C6'));
    dispatch(actions.onChangeShortDesc(''));
    dispatch(actions.onChangeFullDesc(''));
    dispatch(actions.onChangePrice(0));
    dispatch(actions.onChangeSKU(''));
    dispatch(actions.onChangeExpirationDays(''));
    dispatch(actions.onChangeNoExpiration(false));
    dispatch(actions.onChangePercentage(''));
    dispatch(actions.onChangeCashBack(''));
    dispatch(actions.onChangeOriginalPrice(0));
    dispatch(actions.onChangePercentageDisplay(0));
    dispatch(actions.onChangeImage(''));
    dispatch(actions.onSave(false));
    dispatch(actions.onChangeImage(undefined));
    dispatch(actions.changeImageForm({}))
  }

  const returnDisplay = (val: number) => {
    if (val === PRODUCT_SERVICE) {
      return (
        <div className='mt-2 sm:mb-2 md:mb-2 flex'>
          <div className='rectangle text-left sm:w-28 sm:h-28'>
            {!!img_upload?.url ?
              <img src={img_upload?.url} alt="" className='object-cover w-full h-full' />
              :
              <img src={AddIcon} alt="" className="object-center m-auto streamline-icon-coding-apps-we mt-11" />
            }
          </div>
          <div className='inline-block lg:ml-5 ml-6 text-left align-top'>
            <span className='product-image'>Product Image</span><br />
            <span className='accepted-files-jpg mt-3'>*Accepted files jpg, png and must be atleast 512x512 and with 1:1 ratio</span><br />
            <button className='upload mt-3' onClick={handleClick}>Upload</button>
            <input type='file' accept="image/png, image/svg, image/jpeg" ref={hiddenFileInput} style={{ visibility: 'hidden' }} onChange={(e) => handleFileChange(e)} />
            {
              img_error ? <span className='flex text-sm text-red font-sans'>Image must be atleast 512x512 and with 1:1 ratio</span> : ''
            }
          </div>
        </div>
      );
    }
  }

  return (
    <div id="AddPromoCode" className='max-w-screen-md-lg sm:flex-none lg:flex lg:mx-40 mt-20 mb-36 md:mx-40 sm:mx-20 max-sm:mx-10 bg-pale-blue'>
      <div className='h-auto w-screen bg-white border border-solid border-slate-200 rounded-l-md pb-20'>
        <div className='lg:ml-11 lg:mr-28 md:mx-9 sm:mx-7 max-sm:mx-5'>
          <div className='text-left mt-10'>
            <span className='lg:text-h4 md:text-h5 sm:text-h5 max-sm:text-h5 text-black font-bold'>{(valueChecking === PRODUCT_SERVICE) ? 'Add New Product or Service' : 'Add New Promo Code'}</span>
          </div>
          <div className='mt-6'>
            <TextInput width='w-auto' error={on_save === true && prod_promo_title === ''} message='This field is required' placeholder={(valueChecking === PRODUCT_SERVICE) ? 'Product/Service Name*' : 'Title*'} onChange={(e) => changeTitle(e.target.value)} />
          </div>
          {returnDisplay(valueChecking)}
          <div>
            <TextInput
              width='w-auto'
              placeholder='Short Description (Max. 50)'
              value={short_desc}
              onChange={(e) => {
                const { value } = e.target;
                if (value.length > 50) return;
                changeShortDesc(e.target.value);
              }}
            />
          </div>
          <div>
            <textarea className='rectangle-copy-6 w-full resize-none py-4 px-4' placeholder='Full Description' onChange={(e) => changeFullDescription(e.target.value)} />
          </div>
          <div>
            <div className='lg:flex'>
              <div className='lg:inline-block lg:w-1/2 lg:mr-2'>
                <TextInput left_icon={<span className='px-2 text-gray-400 font-sans'>$</span>}
                  placeholder="Price in USD*"
                  onChange={(e) => changePriceInUSD(e.target.value)}
                  width='lg:w-full'
                  error={on_save === true && price_in_usd === 0}
                  message='This field is required'
                  left_icon_border={true}
                />
              </div>
              {valueChecking === PRODUCT_SERVICE ?
                <div className='lg:w-1/2 lg:ml-2'>
                  <TextInput left_icon={<span className='px-2 text-gray-400 font-sans'>$</span>}
                    placeholder="Original Price in USD*"
                    onChange={(e) => onChangeOriginalPrice(e.target.value)}
                    width='lg:w-full'
                    error={on_save === true && original_price === 0}
                    message='This field is required'
                  />
                </div> :
                <div className='lg:w-1/2 lg:ml-2'>
                  <TextInput
                    placeholder="SKU"
                    onChange={(e) => changeSKU(e.target.value)}
                    width='lg:w-full'
                    error={on_save === true && sku === ''}
                    message='This field is required'
                  />
                </div>
              }
            </div>
            {valueChecking === PRODUCT_SERVICE ?
              <div className='lg:mr-3'>
                <TextInput
                  placeholder="SKU"
                  onChange={(e) => changeSKU(e.target.value)}
                  width='lg:w-1/2'
                  error={on_save === true && sku === ''}
                  message='This field is required'
                />
              </div> : ''
            }

          </div>

          <div className='text-left lg:mt-5'>
            <span className='lg:text-lg font-bold'>Expiration</span>
          </div>
          <div className='lg:flex'>
            <div className='mt-4 sm:flex md:flex max-sm:flex lg:flex lg:w-3/4 md:w-full sm:w-full max-sm:w-full'>
              <div className={classNames(no_expiration === true ? 'bg-grey' : '', 'days-upon-purchase flex')}>
                <button className='float-left inline-block w-10 minus-plus' onClick={() => onClickCount('Negative')} disabled={no_expiration}>-</button>
                <div className='float-left inline-block w-20 text-center number-days-counter pt-4'>{expiration_days}</div>
                <button className='float-left w-10 minus-plus' onClick={() => onClickCount('Positive')} disabled={no_expiration}>+</button>
              </div>
              <div className='pt-3 md:inline-block sm:inline-block max-sm:inline-block'>
                <span className='text-sm text-black font-sans leading-4 ml-3'>days upon purchase</span>
              </div>
            </div>
            <div className='lg:inline-block lg:w-1/4 lg:text-right lg:pt-3 md:w-full sm:w-full max-sm:w-full md:my-4 sm:my-4 max-sm:my-4'>
              <Checkbox label='No Expiration' onChange={(e) => onChangeNoExpiration(e.target.checked)} />
            </div>
          </div>

          {valueChecking === PROMO_CODE ?
            <div className='text-left lg:mt-5'>
              <div>
                <span className='lg:text-lg font-bold'>Appearance</span>
              </div>
              <div>
                <div>
                  {
                    appearanceColors.map((item, index) => (
                      <button key={index} className={classNames(item.borderColor, 'appearance-inner-box rounded w-11 h-11')} onClick={() => changeCardColor(item.bgColor)}>
                        <div className={classNames('w-9 h-9 rounded')} style={{ backgroundColor: item.bgColor }}></div>
                      </button>
                    ))
                  }
                </div>
              </div>
            </div> : ''
          }

          {valueChecking === PROMO_CODE ?
            <div className='lg:flex lg:mt-5'>
              <div className='lg:inline-block lg:w-1/2 lg:mr-2'>
                <TextInput
                  placeholder="40%"
                  onChange={(e) => onChangePercentage(e.target.value)}
                  width='w-full'
                  error={on_save === true && percentage === 0}
                  message='This field is required'
                />
              </div>
              <div className='lg:inline-block lg:w-1/2 lg:mr-2'>
                <TextInput
                  placeholder="Cash Back"
                  onChange={(e) => onChangeCashBack(e.target.value)}
                  width='w-full'
                  error={on_save === true && cash_back === ''}
                  message='This field is required'
                />
              </div>
            </div>
            : ''
          }

          <div className='text-left lg:pt-7 lg:flex'>
            <div>
              <Button
                text='SAVE'
                width='sm:w-full max-sm:w-full lg:w-md md:w-full'
                onClick={createProdPromoDetails}
                disabled={isLoading}
              />
            </div>
            <div className='lg:ml-4 max-sm:text-center sm:text-center md:text-center'>
              <button className="h-12 leading-5 max-sm:w-full font-sans text-red text-sm" onClick={onCancel}>Cancel</button>
            </div>
          </div>
        </div>
      </div>

      <div className='h-auto preview-area bg-pale-blue lg:border lg:border-solid lg:border-slate-200 lg:inline-block w-auto rounded-r-md sm:border-none max-sm:border-none md:border-none'>
        <div className='lg:mx-11 md:mx-9 sm:mx-7 max-sm:mx-5'>
          <div className='mt-10'>
            <span className='lg:text-h4 md:text-h5 sm:text-h5 max-sm:text-h5 text-black font-bold'>Preview</span>
          </div>
          <div className='m-auto lg:mx-28 lg:h-96 w-56'>
            <div className='preview-card-image mt-16'>
              <div className={classNames(' no-image')} style={{ backgroundColor: valueChecking === PRODUCT_SERVICE ? '#C6C6C6' : card_color }}>
                <div>
                  {valueChecking === PRODUCT_SERVICE ?
                    <div>
                      {img_src !== '' ?
                        <div className='relative'>
                          <img src={img_src} className="object-cover w-full h-full" />
                          <div className='absolute top-4 right-4 hover:cursor-pointer'>
                            <img src={heart_white} className='w-6 h-5 object-center' />
                          </div>
                        </div>
                        :
                        <div className='relative'>
                          <div className='pt-24 text-base text-dark-grey font-sans' style={{ fontFamily: 'Montserrat' }}>No Image</div>
                          <div className='absolute top-4 right-4 hover:cursor-pointer'>
                            <img src={heart_white} className='w-6 h-5 object-center' />
                          </div>
                        </div>

                      }
                    </div>
                    :
                    <div className='pt-16'>
                      <span className='percentage-cb text-center h-16 text-white text-6xl font-bold'>{percentage !== 0 ? percentage : '0'}%</span><br />
                      <span className='cash-back h-9 text-white font-bold text-xl'>{cash_back}</span>
                    </div>
                  }
                </div>
              </div>
              <div className='h-7 text-left mx-2 pt-1 truncate ...'>
                <span className='font-sans text-lg font-bold'>{prod_promo_title !== '' ? prod_promo_title : valueChecking === PRODUCT_SERVICE ? 'Product Name' : 'Title'}</span><br />
              </div>
              <div className='h-5 text-left mx-2 truncate ...'>
                <span className='text-blue font-sans text-sm'>{business_data?.data.name}</span><br />
              </div>
              <div className='h-5 text-left mx-2 truncate ...'>
                <span className='text-black font-sans text-sm'>{short_desc || 'Short Description'}</span><br />
              </div>
              <div className='h-5 text-left mx-2 mt-4'>
                <span className='product-image text-black text-lg font-bold'>${isNaN(price_in_usd) ? 0 : price_in_usd}</span>
                {valueChecking === PRODUCT_SERVICE ?
                  <span className='text-red text-lg pl-4 font-sans'>{isNaN(percentage_display) ? 0 : percentage_display}% OFF</span> : ''
                }
              </div>
              <div className='mx-2 mt-4'>
                <Button text='ADD TO CART' outlined={true} width='w-full' text_color='text-xs' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProdPromo;

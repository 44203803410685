import { StarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";

import sample_profile from '../../../assets/images/samples/frog.jpeg';
import { useState } from "react";
import Dropzone from "react-dropzone";
import { formatNameForImg } from "../../../helpers/utils";

const ReviewForm = ({ open, setOpen, onSubmit, loading, user } : { open: boolean, setOpen: () => void, onSubmit: any, loading: boolean, user: any }) => {
  const [rating, setRating] = useState(0);
  const [hovered, setHovered] = useState(0);
  const [feedback, setFeedback] = useState('');
  const user_name = formatNameForImg(user?.name || '');

  return (
    <Modal isOpen={open} contentLabel="Example Modal" className="modal-container" onRequestClose={setOpen}>
      <div className='bg-white px-28 py-16 relative'>
        <button className='absolute top-6 right-6 w-6 h-6' onClick={setOpen}>
          <XMarkIcon />
        </button>
          <div className="">
            <h4>Write a review</h4>
            <div className="flex py-5 border-b">
              <div className='w-12 h-12 rounded-full overflow-hidden'>
                <img src={`https://getstream.io/random_svg/?name=${user_name}`} alt="profile" className='w-12 h-12 object-cover' />
              </div>
              <div className="pl-5">
                <h6>{user.name}</h6>
                <div className="text-sm text-dark-grey">{user.email}</div>
              </div>
            </div>
            <div className="flex flex-row py-5">
              Overall Ratings:
              <div className='flex justify-start items-center pl-5'>
                {[1,2,3,4,5].map(i => {
                  return <StarIcon
                    key={i}
                    width={25}
                    height={25}
                    color="#FFCA2C"
                    onMouseOver={() => setHovered(i)}
                    onMouseOut={() => setHovered(0)}
                    onClick={() => setRating(i)}
                    fill={(i <= hovered) || rating >= i ? "#FFCA2C" : "white"}
                  />;
                })}
              </div>
            </div>
            <textarea
              className='rectangle-copy-6 w-full resize-none py-4 px-4 border-border-default border rounded-md text-sm'
              placeholder='Type your review'
              value={feedback || ''}
              onChange={(e) => setFeedback(e.target.value)}
            />
            <div className="w-full h-150 dropzone border mt-1 mb-5 rounded-md justify-center items-center flex">
              <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                      <div {...getRootProps()} className=" justify-center items-center h-100 cursor-pointer">
                        <input {...getInputProps()} />
                        <div className="flex justify-center items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                        </div>
                        <div className="text-center">
                          <p>Drag and drop to upload photos here</p>
                          <p>or browse here</p>
                        </div>
                      </div>
                )}
              </Dropzone>
            </div>
            <Button
              disabled={loading}
              text="SUBMIT REVIEW" width="w-150"
              onClick={() => onSubmit({
                feedback,
                rate: rating
              })}
            />
          </div>
      </div>
    </Modal>
  );
}

export default ReviewForm;